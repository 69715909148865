#taskContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
#taskContainer > div{
    padding: 0.5em 0 0.5em 0;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
}
#headerContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}