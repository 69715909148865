#backlogItems > div {
    width: 35rem;
    padding: 0 0 1rem 0;
}

#backlogItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 70rem;
    justify-content: space-evenly;
}

#backlogContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

#options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
#addElement{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
#editElement{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
