body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
  background: linear-gradient(40deg, #2c3e50 60%, #495b6a 60%);
}

html{
  height:100%;

}

#root{
  height:100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1 {
  text-align: center;
  padding:0 1em 1em 1em;
}

h2 {
  text-align: center;
  padding:0.5em 0.5em 0.5em 0.5em;
}